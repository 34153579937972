<template>
  <div>
    <v-dialog
              max-width="700px"
              height="400px"
              v-model="dialog_stripe"
              @click:outside="dialog_stripe = false"
    >
      <div class="popup__content popup__content_email">
        <p v-if="+tariff_id === 3">Tariff plan: <b>PROFI</b></p>
        <p v-if="+tariff_id === 2">Tariff plan: <b>STANDART</b></p>
        <p v-if="+tariff_id === 3">Price: <b>145$</b> per month</p>
        <p v-if="+tariff_id === 2">Price: <b>70$</b> per month</p>
        <v-btn class="popup__close" small icon @click="dialog_stripe = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <label>Card Information</label>
        <div style="display:flex;margin-top: 10px;">
          <div id="card-number" style="font-size:1.2em;border: 1px solid #ccc;width:100%;padding:10px 7px;border-top-right-radius: 5px;border-top-left-radius: 5px;">
          </div>
        </div>
        <div style="display:flex;margin-top: -1px;">
          <div id="card-expiry" style="border: 1px solid #ccc;width:50%;padding:10px 7px;border-bottom-left-radius: 5px;"></div>
          <div id="card-cvc" style="border: 1px solid #ccc;width:50%;padding:10px 7px;border-bottom-right-radius: 5px;">
          </div>
        </div>
        <div id="card-error" v-if="err_stripe" style="display: flex;align-items: center;margin-top:5px;"><v-icon color="red">mdi-alert-circle</v-icon>
          {{ err_stripe }}</div>
<div style="display: flex;justify-content: center;align-items:center;margin:40px 0">
        <v-btn
            id="custom-button1"
            style="width:130px;min-width:130px;height:40px;"
            class="object__submit btn btn_primary"
            color="primary"
            outlined
            @click="createToken"
            v-if="!loading"
        >Pay</v-btn
        >
        <v-btn
            id="custom-button2"
            outlined
            v-else
            style="width:130px;min-width: 130px;height:40px;"
            class="object__submit btn btn_primary"
            color="primary"
            @click="createToken"
            disabled
        ><v-progress-circular
            style="margin-right: 5px;"
            indeterminate
        />
          Wait</v-btn
        >
</div>
        <div style="display: flex;justify-content: center;margin-bottom: 30px;max-width:100%;align-items:center;">
          <div style="max-width:200px;display:flex;align-items:center;margin-right: 15px;">
            <img src="@/assets/img/stripe.png" alt="">
          </div>
          <div style="max-width:200px;height:56.6px;display:flex;align-items:center;">
            <img src="@/assets/img/cards.png" alt="">
          </div>
        </div>
        <div style="text-align: center;margin-top:40px;">
          If you want to pay by wire transfer <br> please contact with us by email: <a href="mailto:anna@bs.hr">anna@bs.hr</a>
        </div>
      </div>
    </v-dialog>
    <v-dialog max-width="1000px"
        v-model="dialog"
        @click:outside="close"
    >
        <div class="popup__content">
            <v-btn class="popup__close" small icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h5 class="popup__title">{{$t('tariff["Выберите тарифный план отеля"]')}} {{object_change?object_change.name:""}}</h5>
            <ObjectTariff :current="+object_change.tariff_id" @changeTariff="changeTariffIs"/>
            <v-btn class="popup-tariff__btn btn btn_primary"
                color="primary"
                depressed
                @click="changeTariff"
            >
                Save
            </v-btn>
        </div>
    </v-dialog>
    <PopupSuccess ref="PopupSuccess" title="Thank you!" :content="`We received money and activated your tariff plan: ${textTariff}`"/>
  </div>
</template>

<script>
import ObjectTariff from '@/components/ObjectTariff.vue'
import PopupSuccess from "@/components/Popup/PopupSuccess";

export default {
    data: () => ({
        dialog: false,
        object_change:{
          tariff_id:1
        },
      dialog_stripe:false,
      tariff_id:1,
      err_stripe:"",
      loading:false
    }),
    components: {
      PopupSuccess,
        ObjectTariff,
    },
  mounted(){
  },
  beforeCreate() {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = "https://js.stripe.com/v3/";
  },
  computed: {
    stripeElements () {
      return this.$stripe.elements();
    },
    textTariff(){
      if(this.tariff_id === 1){
        return 'Free'
      }else if(this.tariff_id === 2){
        return 'Standart'
      }else{
        return 'Profi'
      }
    },
  },
    methods: {
      async changeTariffIs(tariff){
        this.tariff_id = tariff;
      },
      async changeTariff(){
        if(+this.tariff_id === 1){
          this.object_change.tariff_id = this.tariff_id;
          await this.$store.dispatch('objects/edit', this.object_change );
          this.dialog = false;
        }else{
          this.dialog_stripe = true;
          this.$nextTick(() => {

            const style = {
              base: {
                color:'#000000',
                fontSize:'1.1em',
              },
              invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
              },
            };
            this.cardNumber = this.stripeElements.create('cardNumber', { style });
            this.cardNumber.mount('#card-number');


            this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
            this.cardExpiry.mount('#card-expiry');
            this.cardCvc = this.stripeElements.create('cardCvc', { style });
            this.cardCvc.mount('#card-cvc');
            this.cardNumber.on("change", event => {
              this.err_stripe = '';
            });
            this.cardExpiry.on("change", event => {
              this.err_stripe = '';
            });
            this.cardCvc.on("change", event => {
              this.err_stripe = '';
            });
            })
        }
      },
        open(item) {
           this.object_change = item;
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
      async createToken () {
        this.loading = true;
        let clientSecret;
        await this.$axios.get('/get-intent')
            .then(data => {
              clientSecret =  data.data.client_secret
            })
            .catch(err => {
              this.err_stripe = err.message;
              this.loading = false;
            })
        const { setupIntent, error } = await this.$stripe.confirmCardSetup(
            clientSecret, {
              payment_method: { card: this.cardNumber },
            }
        );
        if(error){
          this.err_stripe = error.message;
          this.loading = false;
        }else{
          await this.$axios.post('/set-subscribe',{
            hotel_id:this.object_change.id,
            plan_id:this.tariff_id,
            setupIntent_payment_method:setupIntent.payment_method,
            setupIntent_id:setupIntent.id
          }).then(data => {
            this.object_change.tariff_id = +this.tariff_id;
            this.$store.dispatch('objects/edit', this.object_change );
            if(this.$route.name === 'Constructor'){
              this.$store.state.landing.free = false;
              if(+this.tariff_id === 3){
                this.$store.state.landing.profi = true;
              }
            }
            this.dialog_stripe = false;
            this.dialog = false;
            this.loading = false;
            this.$refs.PopupSuccess.open();

          }).catch(err => {
            this.err_stripe = err.message;
            this.loading = false;
          })
        }
      },
    }
}
</script>

<style lang="scss" scoped>
.popup-tariff__btn {
    max-width: 255px;
    width: 100%;
    display: block;
    margin: 30px auto 0;
}
</style>
